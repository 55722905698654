<template>
  <session-layout>
    <template slot="session-title">
      <h3>Thank you for your registration</h3>
    </template>
    <template slot="session-body">
      <v-flex xs12 md12 lg12 class="text-center" style="margin-top: 50px;">
        <span class="fs-13 dark-grey-text ">Please check your email and confirm to get started!</span>
      </v-flex>
    </template>
  </session-layout>

</template>

<script>
  import SessionLayout from './Layout'
  import { mapState } from 'vuex'
  export default {
    name: 'Registered',
    components: {
      SessionLayout
    }
  }
</script>